import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import 'bootstrap'

import 'jquery.easing'
import './theme'
import './theme.init'

import intlTelInput from 'intl-tel-input';

document.addEventListener('DOMContentLoaded', function () {
    $('[data-bs-toggle="popover"]').popover()
    const phoneInputs = document.getElementsByClassName('phone-input');

    Array.prototype.forEach.call(phoneInputs, function (phoneInput) {
        let iti = intlTelInput(phoneInput, {
            hiddenInput: "full_number",
            preferredCountries: ['us', 'ca', 'gb', 'tr'],
            separateDialCode: true,
            placeholderNumberType: "MOBILE",
            initialCountry: "auto",
            geoIpLookup: function (success, failure) {
                $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
                    let countryCode = (resp && resp.country) ? resp.country : "us";
                    success(countryCode);
                });
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.3.0/js/utils.js"
        });
    });

    $('.header-nav-features-search-reveal').each(function () {
        let $el = $(this);
        let $header = $('#header');
        let $html = $('html');

        $el.find('.header-nav-features-search-show-icon').on('click', function () {
            $el.addClass('show');
            $header.addClass('search-show');
            $html.addClass('search-show');
            $('#headerSearch').focus();
        });

        $el.find('.header-nav-features-search-hide-icon').on('click', function () {
            $el.removeClass('show');
            $header.removeClass('search-show');
            $html.removeClass('search-show');
        });

    });
});
